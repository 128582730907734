import React from "react";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import { Segment, Table, Message, Item, Dropdown } from "semantic-ui-react";
import { Form, TextArea, Checkbox } from "formsy-semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import { DatatableFilter } from "./DatatableFilter";
import DialogBoxDetail from "./DialogBoxDetail";
import { DatatableRow } from "./DatatableRow";
import utilFunc from "../../../../../util_funs";

const tableHeader = [
  {
    title: "Actions",
    width: "1",
    sort: "",
  },
  {
    title: "Notification Type",
    width: "2",
    sort: "",
  },
  {
    title: "Name",
    width: "2",
    sort: "",
  },
  {
    title: "Email/Phone",
    width: "3",
    sort: "",
  },
  {
    title: "Notify via",
    width: "2",
    sort: "",
  }
];
const queryParams = ["_limit", "_order", "_sort", "q", "_page"];
export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "user_id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalErrorMsgLegacy: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      modalstatusNotiAandMisc: false,
      modalstatusNotiAandMiscData: {},
      ISDrenderOption1: [],
      ISDrenderOption2: [],
      notiAndSettingmodalData: {},
      loadNow: false,
      currentChecked: [],
      currentExpanded: [],
      hipaaLoginEmailList: [],
      hipaaLoginPhoneList: [],
      notifyvia: {
        email: true,
        phonecall: false,
        text: false
      },
      error: {},
      messageDisplay: 'none',
      color: 'green',
      resMessage: '',
      buttonDisable: false,
      modalFooter: true,
      selectedBranch: ['anybranch'],
      selectedAnswers:[],
      voice_type: "voice",
      notifyviaError:false,
      notifyviaError1:false,
    };
  }

  componentDidMount() {
    this.loadData({});

    // this.getAccessModules();
    api.user
      .getProfile()
      .then((resp) => {
        if (resp.data && resp.data.data.profileDetails) {
          const remailCountryCodeArr = resp.data.data.remailCountryCodeArr;
          const defaultCountryCodeArr = resp.data.data.defaultCountryCodeArr;
          this.setState({ ISDrenderOption1: defaultCountryCodeArr });
          this.setState({ ISDrenderOption2: remailCountryCodeArr });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter) => {
    if (filter !== this.state.q) {
      this.loadData({ q: filter, _page: 1 });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalbuttofun = (data) => {
    if (data.modalType == "Delete") {
      this.onDeleteCompletedChatAction();
    }
    // if (data.modalType == 'Popup') {
    //   this.setState({
    //     modalstatus: false,
    //     modaltitle: '',
    //     modalmessage: '',
    //     modalbuttonmsg: {},
    //     modalType: ''
    //   });
    // }
  };

  branchOptionList = () => {
    const firstIndex = {
      key: 'anybranch',
      text: 'Any branch',
      value: 'anybranch'
    };
    let list = this.state.workspaceBranches.map((key, index) => {
      return {
        key: key.branch_id,
        text: key.branch_name,
        value: key.branch_id
      }
    });
    list.splice(0, 0, firstIndex);
    return list;
  }

  branchansList=(ans)=>{
    console.log(ans,"answer");
       let parsed=this.state.parsed_values;
       let opts=parsed.filter((val)=>val?.key===ans[0]?.key);
       let list=opts[0]?.val.map((item)=>{
        return {
         key:ans[0].key,
         text:item,
         value:item
        }
       });
       console.log(list,"list list")
       return list;
  }

  handleBranchSelection = (e, d) => {
    const memberData = this.state.modaldata;
    memberData.voice_brances = d.value;
    this.setState({ selectedBranch: d.value,  modaldata: memberData},()=>{
      if(this.state.voice_type=="Turbo" && this.state.selectedBranch){
        this.branchOptionValues(this.state.selectedBranch);
      }
    });
  }
  //
  branchOptionValues = (list) => {
    let vals=[];
    if(list.length > 0) {
      list=list.filter((val)=>val!=='anybranch');
       vals=list.map((branch,i)=>{
            if(branch!=='anybranch' && branch!=='Any branch'){
              let s=this.state.parsed_values.filter((vall,i,arr)=>{
                  return vall['key']==branch;
              });
             return  s;
            }else{
              return '';
            }
      });
    }
    let savedvlas=this.state.modaldata.branch_values ? this.state.modaldata.branch_values:[];
    if(vals.length===0){
       savedvlas=[];      
    }
    console.log(vals,savedvlas,'rem')
    if(vals.length && savedvlas.length){
      let newans=savedvlas.filter((val,i)=>{
            let j=vals.findIndex((val1)=>{
              console.log(val.key,val1[0]?.key,'rem 2');
                return val.key===  val1[0]?.key
            });
            if(j>=0){
              return true;
            }
      });
        console.log(newans,'rem 1');
        savedvlas=newans;
    }
    let modaldata=this.state.modaldata;
    modaldata.branch_values=savedvlas;
    modaldata.selectedBranchValues=vals;
    this.setState({modaldata:modaldata},()=>{
      this.setState({modalmessage: this.memberEditBody()});
    });
  }

  handleAnswerSelection=(e,d)=>{
    console.log(d.value,d.name,this.state.modaldata?.branch_values,"values");
    let values=this.state.modaldata?.branch_values;
    let search=values.findIndex((item)=>item?.key==d.name);
    if(search>-1){
       let find=values[search];
       find['values']=d.value;
       values[search]=find;
    }else{
      values.push({key:d.name,values:d.value});
    }
    console.log(values,"values");
    let modl=this.state.modaldata;
    modl.branch_values=values;
    console.log(modl,"values");
    this.setState({modaldata:modl},()=>{
      this.setState({modalmessage: this.memberEditBody()});
    });
  }

  editMember = (id) => {

    this.setState({ loading: true });
    api.manageUser.getVoiceWorkspaceBranch({}).then((res) => {
      if (res.status) {
        let recordlist = this.state.lists.findIndex((list) => list.id === id);
        let modaldata = this.state.lists[recordlist];
        modaldata.selectedBranchValues=[];
        // modaldata.voice_brances=[];

        if (modaldata.email.includes('/')) {
          const phoneEmail = modaldata.email.split('/');
          modaldata.email = phoneEmail[0];
          modaldata.phone = phoneEmail[1];
        }
        const notifi_data = {
          email: false,
          phonecall: false,
          text: false
        };

        modaldata.notify_via.map((item, index) => {
          if (notifi_data.hasOwnProperty(item)) {
            notifi_data[item] = true
          }
        });

          let vals=[];
          if((typeof modaldata.voice_brances !='undefined'  && modaldata.voice_brances.length > 0) && res.data.type=='Turbo') {
            console.log(modaldata.voice_brances,res.data.parsed_values,'turbooo');
            let vlist=modaldata.voice_brances.filter((v)=>v.trim()!=='anybranch' && v.trim()!=='Any branch');
            vals=vlist.map((branch,i)=>{
                  if(branch!=='anybranch' && branch!=='Any branch'){
                    let s=res.data.parsed_values.filter((vall,i,arr)=>{
                        return vall['key']===branch;
                    });
                  return  s;
                  }
            });
          }else{
            vals=[];
          }
          if(vals.length==1 && typeof vals[0]!='undefined' && ( vals[0]=='' || vals[0].length==0)){
            vals=[];
          }
        modaldata.selectedBranchValues=vals;
        this.setState({
          modaldata: modaldata,
          modalbuttonmsg: { submit: "Submit", cancel: "Close" },
          modalstatus: true,
          modaltitle: "Edit notification member details",
          modalType: "",
          modalFooter: false,
          notifyvia: notifi_data,
          workspaceId: res.data.workspaceId,
          workspaceBranches: res.data.data,
          selectedBranch: modaldata.voice_brances,
          selectedAnswers:modaldata.branch_values,
          parsed_values:res.data.parsed_values,
          voice_type:res.data.type,
          loading: false
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
         
        });
      }
    })
  }

  deleteCompletedChat = (id, table) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Delete",
      modaldata: modaldata,
    });
  };

  setCurrentCheck = (checked) => {
    this.setState({
      currentChecked: checked,
    });
  };
  setCurrentExpand = (checked) => {
    this.setState({
      currentExpanded: checked,
    });
  };

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
      modalFooter: true,
      notifyviaError: false,
      notifyviaError1: false,
      selectedBranchValues:[],
    });
  };

  onValidSubmitMember = () => {

    if (!this.state.modaldata.notify_via.length) {
      this.setState({
        notifyviaError: true
      }, () => {
        this.setState({
          modalmessage: this.memberEditBody()
        })
      });
      return;
    }
    let selbranch=this.state.selectedBranch ?? [];
    selbranch=selbranch?.length===1 && selbranch[0]?.trim()==='' ? []:selbranch;
    
    if (selbranch.length===0) {
      this.setState({
        notifyviaError1: true
      }, () => {
        this.setState({
          modalmessage: this.memberEditBody()
        })
      });
      return;
    }
    this.setState({
      loading: true,
      buttonDisable: true
    }, () => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
    api.manageUser.updateNotificationMember(this.state.modaldata).then((res) => {
      this.setState({ loading: false, buttonDisable: false })
      if (res.data.status) {
        this.setState({
          messageDisplay: 'block',
          color: 'green',
          resMessage: res.data.message
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
        });
      } else {
        this.setState({
          messageDisplay: 'block',
          color: 'red',
          resMessage: res.data.message
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
        });
      }
      setTimeout(() => {
        this.setState({
          messageDisplay: 'none'
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
        });
        if(res.data.error!=="duplicate" && res.data.status){
          this.loadData({});
          this.modalClose(false, "");
        }
       
      }, 2000);
    }).catch((err) => {
      console.log(err);
    })
  }

  phoneFormat = (p) => {
    p = p.replace(/[^\d]*/gi, "");
    if (p.length <= 3) {
      return p;
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;
      return pp;
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      return finalPhone;
    }
  };

  handlePhoneChange = (e) => {
    const memberData = this.state.modaldata;

    let phone1 = e.target.value;
    let p = this.phoneFormat(phone1);
    memberData.phone = p;
    setTimeout(function () {
      document.getElementById("phoneNumberIndiviNoti").value = p;
    }, 100);
    this.setState({
      modaldata: memberData,
      email1: "",
      disableBtn: false
    });
  };

  handleChangeISD = (e) => {
    const memberData = this.state.modaldata;
    memberData.country_code = e.target.value;
    this.setState({
      modaldata: memberData,
    }, () => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
  };

  memberEditBody = () => {
    console.log("name", this.state.modaldata.name);
    const errorLabel = <div className="red" />;
    return (
      <div className="col-md-12">
        <Message
          color={this.state.color}
          style={{
            display: this.state.messageDisplay,
          }}
        >
          {this.state.resMessage}
        </Message>
        <Form
          noValidate
          autoComplete="off"
          className="manageNotificationIndiviNoti"
          id="manageNotificationIndiviNoti"
          onValidSubmit={this.onValidSubmitMember}
        >
          <div className="form-group">
            <label style={{ marginBottom: 0 }}>Full Name</label>
            <Form.Input
              type="text"
              className="1"
              name="name"
              validations="isRequired"
              validationErrors={{
                isRequired: "This field is required.",
              }}
              onChange={this.onchangeenable}
              errorLabel={errorLabel}
              defaultValue={this.state.modaldata.name}
            />
          </div>
          <div
            className="form-group"
            style={{ marginBottom: 10 }}
          >
            <label style={{ marginBottom: 0 }}>
              Email Address
            </label>
            {this.state.notifyvia.email ?
              <Form.Input
                type="email"
                className=""
                name="email"
                validations="isEmail,isRequired"
                validationErrors={{
                  isEmail:
                    "Please enter a valid email address.",
                  isRequired: "This field is required.",
                }}
                errorLabel={errorLabel}
                onChange={this.onchangeenable}
                id="emailIndiviNoti"
                defaultValue={this.state.modaldata.email}
              /> :
              <Form.Input
                type="email"
                className=""
                name="email"
                errorLabel={errorLabel}
                onChange={this.onchangeenable}
                id="emailIndiviNoti"
                defaultValue={this.state.modaldata.email}
              />
            }
          </div>
          <div
            className="form-group"
            style={{ marginBottom: 10 }}
          >
            <label>Phone Number</label>
            <div className="d-flex">
              <select
                class="form-control"
                name="country_code"
                id="isd_code"
                onChange={this.handleChangeISD}
                style={{ maxWidth: 166, marginRight: "1rem" }}
                defaultValue={this.state.modaldata.country_code}
              >
                {this.renderDropDownDefault(
                  this.props.ISDrenderOption1
                )}
                <optgroup label="Other countries">
                  {this.renderDropDownRemaining(
                    this.props.ISDrenderOption2
                  )}
                </optgroup>
              </select>
              {(this.state.notifyvia.text || this.state.notifyvia.phonecall) ?
                <Form.Input
                  fluid
                  autoComplete="off"
                  name="phone"
                  maxLength="13"
                  onChange={this.handlePhoneChange}
                  placeholder="Phone Number"
                  validations="isRequired,usPhoneLength"
                  validationErrors={{
                    isRequired: "This field is required.",
                    usPhoneLength:
                      "Phone number must be at least 10 numbers.",
                  }}
                  id="phoneNumberIndiviNoti"
                  className="w-100"
                  errorLabel={errorLabel}
                  defaultValue={this.state.modaldata.phone}
                /> :
                <Form.Input
                  fluid
                  autoComplete="off"
                  name="phone"
                  maxLength="13"
                  onChange={this.handlePhoneChange}
                  placeholder="Phone Number"
                  id="phoneNumberIndiviNoti"
                  className="w-100"
                  errorLabel={errorLabel}
                  defaultValue={this.state.modaldata.phone}
                />
              }
            </div>
          </div>
          <div className="row form-group" style={{ marginBottom: 10 }}>
            <div className="col-md-6">
              <div className="form-group">
                <label>Notification Type</label>
                <select className="form-control" name="notification_type" onChange={this.onchangeenable} defaultValue={this.state.modaldata.notification_type}>
                  <option value="1">All notifications</option>
                  <option value="2">Schedule based</option>
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <label>Notify via</label>
              <div style={{ display: 'flex', columnGap: 10 }}>
                <Checkbox
                  name="notifyvia"
                  // value={1} 
                  label={{ children: 'Email' }}
                  onChange={() => this.changeNotifyVia('email')}
                  defaultChecked={this.state.modaldata?.notify_via?.includes('email')}
                />
                <Checkbox name="notifyvia" label={{ children: 'Text' }} onChange={() => this.changeNotifyVia('text')} defaultChecked={this.state.modaldata?.notify_via?.includes('text')} />
                <Checkbox name="notifyvia" label={{ children: 'Phone Call' }} onChange={() => this.changeNotifyVia('phonecall')} defaultChecked={this.state.modaldata?.notify_via?.includes('phonecall')} />
              </div>

              {this.state.notifyviaError && <div class="red">This field is required.</div>}
            </div>
          </div>
          <div className="form-group">
            <label>Notify if call goes through</label>
            <Dropdown
              placeholder=''
              fluid
              multiple
              search
              selection
              options={this.branchOptionList()}
              defaultValue={this.state.selectedBranch}
              onChange={this.handleBranchSelection}
            // value={this.state.selectedBranch}
            />
            {this.state.notifyviaError1 && <div class="red">This field is required.</div>}
          </div>

          <div style={{marginTop:'20px',marginBottom:'30px'}}>
            {(this.state.voice_type=='Turbo' && this.state.modaldata?.selectedBranchValues?.length>0 && this.state.modaldata?.voice_brances?.length>0) && 
              <div className="row">
                  {this.state.modaldata?.selectedBranchValues.map((item,i)=>{
                    let ans=[];
                    let values=[];
                    
                    if(item && item!==''){
                       let branchvals=this.state.modaldata?.branch_values && this.state.modaldata?.branch_values!=='undefined' ? this.state.modaldata?.branch_values :[];
                      ans=branchvals.filter((it)=>it.key===item[0]?.key);
                      if(ans.length>0){
                        values=ans[0]?.values;
                     }else{
                       values=[];
                     }
                      if(item[0]?.val===""){
                        return (<></>);
                      }
                    return( <div style={{width:'100%'}}>{(typeof item[0]!=='undefined' && typeof item[0].key!=='') ? <div  className="form-group col-md-6" key={item[0]?.key}>
                    <label>Branch Value for {item[0]?.key}</label>
                         <Dropdown
                           key={item[0]?.key}
                           name={item[0]?.key}
                           placeholder=''
                           fluid
                           multiple
                           search
                           selection
                           options={this.branchansList(item)}
                           defaultValue={values}
                           onChange={this.handleAnswerSelection}
                          // value={ans?.values}
                         />
                    </div>:<></>}</div> );
                    }
                  })} 
              </div>
            }
          
            
          </div>

          <div className="form-group" style={{ display: 'flex', marginTop: 10, columnGap: 10, justifyContent: 'right' }}>
            <button
              disabled={this.state.buttonDisable}
              type="button"
              onClick={() => this.modalClose(false, "")}
              style={{ display: this.state.modalbuttonmsg.cancel != "" ? "block" : "none" }}
              className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
              data-dismiss="modal"
            >
              <span>Close</span>
            </button>
            <button
              disabled={this.state.buttonDisable}
              type="submit"
              // onClick={() => this.modalbuttofun(this.state.modalType)}
              style={{ display: this.state.modalbuttonmsg.submit != "" ? "block" : "none" }}
              className="btn linear-gradient yr-submitbtn btn-sm"
            >
              Submit
            </button>
          </div>
        </Form>
      </div>
    )
  }

  changeNotifyVia = (fieldname) => {
    const memberData = this.state.modaldata;
    let notifyData = memberData.notify_via;
    if (!notifyData.includes(fieldname)) {
      notifyData.push(fieldname);
    } else {
      const findIndex = notifyData.findIndex((v, i) => v == fieldname);
      if (findIndex !== -1) {
        notifyData.splice(findIndex, 1);
      }
    }
    memberData.notify_via = notifyData;
    const notifyvia = this.state.notifyvia;
    notifyvia[fieldname] = !notifyvia[fieldname];
    this.setState({
      modaldata: memberData,
      notifyvia: notifyvia,
      notifyviaError: false,
      notifyviaError1:false
    }, () => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    })
  }

  renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {
    return defaultCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : ""}
        >
          {option.country_name}
        </option>
      );
    });
  };

  renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {
    return remailCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : "qqq"}
        >
          {option.country_name}
        </option>
      );
    });
  };

  onchangeenable = (e) => {
    const memberData = this.state.modaldata;
    memberData[e.target.name] = e.target.value;
    this.setState({
      modaldata: memberData,
    }, () => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
  }

  reloadDataTable = () => {
    this.loadData();
  };
  onDeleteCompletedChatAction = () => {
    this.setState({ modalFormLoader: true });
    api.manageUser
      .deleteVoiceListMember({
        id: this.state.modaldata.id,
        table: this.state.modaldata.table,
      })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          let recordDeleted = this.state.lists.findIndex(
            (list) => list.id === data.data.id
          );
          let oldArr = this.state.lists;
          oldArr.splice(recordDeleted, 1);
          this.setState({
            modalstatus: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modalErrorMsg: false,
          });

          this.setState({
            loading: "none",
            message2: "block",
            color2: "green",
            msg2: "Ai phone call notification member has been deleted successfully.",
          });
          this.props.UpdateToasterMsgThree(this.state);
          this.loadData({});
        } catch (err) {
          console.log(err);
        }
      });
  };

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    // let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    // let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    // let time_zone = clientTimeZone;

    // params["time_zone"] = time_zone;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.manageUser.getVoiceLists(query).then((data) => {
        try {
          // let accessModuleStore = data.user_access;
          // data.user_access.forEach((item, index) => {
          //   item.forEach((item2, index2) => {
          //     accessModuleStore.push(item2);
          //   });
          // });
          let datatableRowstemp;
          var isd1 = "+1";
          var phoneno = "";
          var is_recieve_text_message_on_livechat = "0";
          var is_recieve_email_on_livechat = "0";
          var jumpin_message = "";
          var is_recieve_text_message_on_completechat = "0";
          var is_recieve_email_on_completechat = "0";
          var is_leave_note = "0";
          if (data.data.totalrecord) {
            datatableRowstemp = data.data.list.map((list, index) => (
              <DatatableRow
                key={index}
                list={list}
                editUserSubAccounts={this.editUserSubAccounts}
                deleteHipaaUserSubAccounts={this.deleteHipaaUserSubAccounts}
                preOpensetting={this.preOpensetting}
                sessionUser={this.props.sessionUser}
                deleteCompletedChat={this.deleteCompletedChat}
                editMember={this.editMember}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            lists: data.data.list,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  render() {
    return (
      <Segment>
        {this.state.loading && <div className="ui loading form"></div>}
        <DatatableFilter
          filter={this.state.q}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
        />
        <DialogBoxDetail
          obj={this.state}
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalClose={this.modalClose}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
          currentChecked={this.state.currentChecked}
          currentExpanded={this.state.currentExpanded}
          setCurrentCheck={this.setCurrentCheck}
          setCurrentExpand={this.setCurrentExpand}
          modalFooter={this.state.modalFooter}

        />
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          limit={this.state._limit.toString()}
        />
      </Segment>
    );
  }
}
