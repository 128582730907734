import React from "react";
import { connect } from "react-redux";
import UserProfile from "./UserProfile";
import ManageUser from "./ManageUser/ManageUser";
import ManageHipaaUser from "./ManageHipaaUser/ManageHipaaUser";
import SensitiveDataActivityLog from "./SensitiveDataActivityLog/SensitiveDataActivityLog";
import SupportTickets from "./SupportTickets";
import { Message } from "semantic-ui-react";
import utilFunc from "../../../util_funs";
import Billing from '../../Billing/Billing';
import '../../Billing/billing.css';


import {
  setPageTitle,
  toggleNavigation,
  setBreadCrum,
  setMessage,
  toggleBreadcrum
} from "../../../store/Page";
import { Link } from "react-router-dom";
import Integration from "./Integration/Integration";
import ApiInstruction from "./ApiInstruction";
const queryString = require("query-string");

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "profile",
      toggleCreateTicketBtn: false,
      addUserBt: false,
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      profileTab: "active",
      billing:"",
      userTab: "",
      sensitiveTab: "",
      supportTab: "",
      hipaaUserTab: "",
      ticketSuccessMessage: "",
      addHipaaUserBt:"",
      integrationTab: "",
      apiInstructionTab: ""
     
    };
  }

  componentWillReceiveProps(prevProps, prevState){
   
    const {
      setPageTitle,
      toggleNavigation,
      location,
      PageMessage,
      setMessage,
      setBreadCrum,
      toggleBreadcrum
    } = prevProps;
    const params = queryString.parse(location.search);
    if (params) {
      if (params.tab !== undefined && params.tab === "support") {
        if (PageMessage) {
          this.setState(
            {
              ticketSuccessMessage: PageMessage,
            },
            () => {
              utilFunc.scrollTop(800);

              setTimeout(() => {
                this.setState({ ticketSuccessMessage: false });
                setMessage({ message: null });
              }, 10000);
            }
          );
        }
        this.toggleTab("support");
      }
      if (params.tab !== undefined && params.tab === "manageuser") {
        this.toggleTab("user");
      }
      if (params.tab !== undefined && params.tab === "profile") {
        this.toggleTab("profile");
      }
      if (params.tab !== undefined && params.tab === "billing") {
        this.toggleTab("billing");
      }

      if (params.tab !== undefined && params.tab === "sensitive") {
        this.toggleTab("sensitive");
      }

      if (params.tab !== undefined && params.tab === "hipaauser") {
        this.toggleTab("hipaauser");
      }
      
      if (params.tab !== undefined && params.tab === "integration") {
        this.toggleTab("integration");
    }

    if (params.tab !== undefined && params.tab === "api-instructions") {
        this.toggleTab("apiInstruction");
    }
      
    }
  }
  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      location,
      PageMessage,
      setMessage,
      setBreadCrum,
      toggleBreadcrum
    } = this.props;
    setPageTitle({ title: "Settings", subHeaderTitle: "Settings" });
    setBreadCrum({ breadcrum: null });

    toggleNavigation({ toggle: false });
    toggleBreadcrum({ toggle: false });
    const params = queryString.parse(location.search);
    if (params) {
      if (params.tab !== undefined && params.tab === "support") {
        if (PageMessage) {
          this.setState(
            {
              ticketSuccessMessage: PageMessage,
            },
            () => {
              utilFunc.scrollTop(800);

              setTimeout(() => {
                this.setState({ ticketSuccessMessage: false });
                setMessage({ message: null });
              }, 10000);
            }
          );
        }
        this.toggleTab("support");
      }
      if (params.tab !== undefined && params.tab === "manageuser") {
        this.toggleTab("user");
      }
      if (params.tab !== undefined && params.tab === "profile") {
        this.toggleTab("profile");
      }
      if (params.tab !== undefined && params.tab === "billing") {
        this.toggleTab("billing");
      }
      if (params.tab !== undefined && params.tab === "sensitive") {
        this.toggleTab("sensitive");
      }
      if (params.tab !== undefined && params.tab === "integration") {
        this.toggleTab("integration");
      }
      if (params.tab !== undefined && params.tab === "api-instructions") {
        this.toggleTab("apiInstruction");
      }
      
    }
  }

  getClassName = (tab, isNav = true) => {
    let parentClass = "nav-link";
    let childClass = "tab-pane";

    if (isNav) {
      if (this.state.currentTab === tab) {
        parentClass = parentClass + " active";
      }

      return parentClass;
    } else {
      if (this.state.currentTab === tab) childClass = childClass + " active";
      return childClass;
    }
  };

  toggleTab = (tab) => {
    const params = queryString.parse(this.props.location.search);
    if (tab === "profile") {

      this.setState({
        addUserBt: false,
        profileTab: "active",
        userTab: "",
        sensitiveTab: "",
        supportTab: "",
        hipaaUserTab:"",
        toggleCreateTicketBtn: false,
        addHipaaUserBt:false,
        billing:"",
        integrationTab: "",
        apiInstructionTab: ""
      });
    
      if(params.tab !='profile'){
      this.props.history.push('settings?tab=profile');
      }

    }else if (tab === "billing") {
      this.setState({
        addUserBt: false,
        profileTab: "",
        userTab: "",
        sensitiveTab: "",
        supportTab: "",
        hipaaUserTab:"",
        toggleCreateTicketBtn: false,
        addHipaaUserBt:false,
        billing:"active",
        integrationTab: "",
        apiInstructionTab: ""
      });
      if(params.tab !='billing'){
        this.props.history.push('settings?tab=billing');
        }
      
    } else if (tab === "user") {
      this.setState({
        addUserBt: true,
        profileTab: "",
        userTab: "active",
        sensitiveTab: "",
        supportTab: "",
        hipaaUserTab:"",
        toggleCreateTicketBtn: false,
        addHipaaUserBt:false,
        billing:"",
        integrationTab: "",
        apiInstructionTab: ""
      });
  
      if(params.tab !='manageuser'){
      this.props.history.push('settings?tab=manageuser');
      }
      
    } else if (tab === "sensitive") {
      this.setState({
        addUserBt: false,
        profileTab: "",
        userTab: "",
        sensitiveTab: "active",
        supportTab: "",
        hipaaUserTab:"",
        toggleCreateTicketBtn: false,
        addHipaaUserBt:false,
        billing:"",
        integrationTab: "",
        apiInstructionTab: ""
      });
      if(params.tab !='sensitive'){
        this.props.history.push('settings?tab=sensitive');
        }
   
    } else if (tab === "support") {
      this.setState({
        addUserBt: false,
        profileTab: "",
        userTab: "",
        sensitiveTab: "",
        supportTab: "active",
        hipaaUserTab:"",
        toggleCreateTicketBtn: true,
        addHipaaUserBt:false,
        billing:"",
        integrationTab: "",
        apiInstructionTab: ""
      });
      if(params.tab !='support'){
        this.props.history.push('settings?tab=support');
        }
    } else if (tab === "hipaauser") {
      this.setState({
        addUserBt: false,
        profileTab: "",
        userTab: "",
        sensitiveTab: "",
        supportTab: "",
        hipaaUserTab:"active",
        toggleCreateTicketBtn: false,
        addHipaaUserBt:true,
        billing:"",
        integrationTab: "",
        apiInstructionTab: ""
      });
      if(params.tab !='hipaauser'){
        this.props.history.push('settings?tab=hipaauser');
        }
      
    }else if(tab === "integration"){
      this.setState({
        addUserBt: false,
        profileTab: "",
        userTab: "",
        sensitiveTab: "",
        supportTab: "",
        hipaaUserTab:"",
        toggleCreateTicketBtn: false,
        addHipaaUserBt:false,
        billing:"",
        integrationTab: "active",
        apiInstructionTab: ""
      });
      if(params.tab !='integration'){
        this.props.history.push('settings?tab=integration');
    } 
    }else if(tab === "apiInstruction"){
      this.setState({
        addUserBt: false,
        profileTab: "",
        userTab: "",
        sensitiveTab: "",
        supportTab: "",
        hipaaUserTab:"",
        toggleCreateTicketBtn: false,
        addHipaaUserBt:false,
        billing:"",
        integrationTab: "",
        apiInstructionTab: "active"
      });
      if(params.tab !='api-instructions'){
        this.props.history.push('settings?tab=api-instructions');
      }
    }  
  };

  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };

  render() {
    return (
      <div className="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid">
            <div className="yr-body-p">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="kt-portlet kt-portlet--tabs yr-mt-20 shadow-none">
                    <div className="kt-portlet__head">
                      <div className="kt-portlet__head-toolbar">
                        <ul
                          className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                          role="tablist"
                        >
                          <li style={{display:this.props.user.totalAvailableModules.includes("profile")?"":"none"}} className="nav-item">
                            <a
                              className={"nav-link " + this.state.profileTab}
                              data-toggle="tab"
                              role="tab"
                              onClick={() => this.toggleTab("profile")}
                              style={{ cursor: "pointer" }}
                            >
                              Profile
                            </a>
                          </li>
						              <li style={{display:this.props.user.totalAvailableModules.includes("billing")?"":"none"}} className="nav-item">
                            <a
                              className={"nav-link " + this.state.billing}
                              data-toggle="tab"
                              style={{ cursor: "pointer" }}
                              role="tab"
                              onClick={() => this.toggleTab("billing")}
                            >
                              Billing
                            </a>
                          </li>
                          {!this.props.user.user_subaccount_id && (
                            <li className="nav-item">
                              <a
                                style={{ cursor: "pointer" }}
                                className={"nav-link " + this.state.userTab}
                                data-toggle="tab"
                                role="tab"
                                onClick={() => this.toggleTab("user")}
                              >
                                Manage Users
                              </a>
                            </li>
                          )}

                          {!this.props.user.user_subaccount_id && (
                            <li className="nav-item">
                              <a
                                style={{ cursor: "pointer" }}
                                className={"nav-link " + this.state.hipaaUserTab}
                                data-toggle="tab"
                                role="tab"
                                onClick={() => this.toggleTab("hipaauser")}
                              >
                                HIPAA Authorized Accounts
                              </a>
                            </li>
                          )}
                          <li style={{display:this.props.user.totalAvailableModules.includes("sensitive_data")?"":"none"}} className="nav-item">
                            <a
                                className={"nav-link " + this.state.sensitiveTab}
                              data-toggle="tab"
                              style={{ cursor: "pointer" }}
                              role="tab"
                              onClick={() => this.toggleTab("sensitive")}
                            >
                              Sensitive Data Activity Log
                            </a>
                          </li>
                          <li style={{display:this.props.user.totalAvailableModules.includes("supportticket")?"":"none"}} className="nav-item">
                            <a
                              className={"nav-link " + this.state.supportTab}
                              data-toggle="tab"
                              style={{ cursor: "pointer" }}
                              role="tab"
                              onClick={() => this.toggleTab("support")}
                            >
                              Support Tickets
                            </a>
                          </li>
                          <li style={{display:this.props.user.totalAvailableModules.includes("integration")?"":"none"}} className="nav-item">
                            <a
                              className={"nav-link " + this.state.integrationTab}
                              data-toggle="tab"
                              style={{ cursor: "pointer" }}
                              role="tab"
                              onClick={() => this.toggleTab("integration")}
                            >
                              Integrations
                            </a>
                          </li>
                          <li style={{display:this.props.user.totalAvailableModules.includes("api_instructions")?"":"none"}} className="nav-item">
                            <a
                              className={"nav-link " + this.state.apiInstructionTab}
                              data-toggle="tab"
                              style={{ cursor: "pointer" }}
                              role="tab"
                              onClick={() => this.toggleTab("apiInstruction")}
                            >
                              API Instructions
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div style={{ paddingTop: 10 }}>
                        {this.state.addUserBt && (
                          <button
                            className="btn linear-gradient yr-add-new float-right"
                            data-toggle="modal"
                            onClick={() => this.child.adduser()}
                          >
                            Add User
                          </button>
                        )}

                        {this.state.toggleCreateTicketBtn && (
                          <Link
                            to="/support/new"
                            className="btn linear-gradient yr-add-new float-right premium_create_ticket"
                          >
                            Create Ticket
                          </Link>
                        )}

                        {this.state.addHipaaUserBt && (
                          <button
                            className="btn linear-gradient yr-add-new float-right"
                            data-toggle="modal"
                            onClick={() => this.childHipaa.addHipaaUser()}
                          >
                            Add Hipaa User
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="kt-portlet__body yr-pd0-lr">
                      <div className="tab-content">
                        <div
                          className={"tab-pane " + this.state.profileTab}
                          id="kt_portlet_base_demo_1_1_tab_content"
                          role="tabpanel"
                        >
                          <UserProfile />
                        </div>
                        {!this.props.user.user_subaccount_id && (
                          <div
                            className={
                              "tab-pane yr-manageUser-table " +
                              this.state.userTab
                            }
                            id="kt_portlet_base_demo_1_2_tab_content"
                            role="tabpanel"
                          >
                            <Message
                              color={
                                this.state.userToasterColor
                                  ? this.state.userToasterColor
                                  : "green"
                              }
                              style={{
                                display: this.state.userToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.userToasterMsg}
                            </Message>
                            <ManageUser
                              ref={(child) => {
                                this.child = child;
                              }}
                              showUserToaster={this.showToaster}
                              sessionUser={this.props.user}
                            />
                          </div>
                        )}
                        {!this.props.user.user_subaccount_id && (
                          <div
                            className={
                              "tab-pane yr-manageUser-table " +
                              this.state.hipaaUserTab
                            }
                            id="kt_portlet_base_demo_1_2_tab_content"
                            role="tabpanel"
                          >
                            <Message
                              color={
                                this.state.userToasterColor
                                  ? this.state.userToasterColor
                                  : "green"
                              }
                              style={{
                                display: this.state.userToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.userToasterMsg}
                            </Message>
                            <ManageHipaaUser
                              ref={(childHipaa) => {
                                this.childHipaa = childHipaa;
                              }}
                              showUserToaster={this.showToaster}
                              sessionUser={this.props.user}
                            />
                          </div>
                        )}
                        <div
                           className={
                            "tab-pane yr-manageUser-table " +
                            this.state.sensitiveTab
                          }
                          id="kt_portlet_base_demo_1_3_tab_content"
                          role="tabpanel"
                        >
                          <SensitiveDataActivityLog user = {this.props.user} />
                        </div>
                        <div
                          className={
                            "tab-pane yr-manageUser-table " +
                            this.state.supportTab
                          }
                          id="kt_portlet_base_demo_1_4_tab_content"
                          role="tabpanel"
                        >
                          {this.state.ticketSuccessMessage && (
                            <Message color="green">
                              {this.state.ticketSuccessMessage}
                            </Message>
                          )}

                          <SupportTickets history={this.props.history} />
                        </div>
						<div
                          className={
                            "tab-pane yr-manageUser-table " +
                            this.state.billing
                          }
                          id="kt_portlet_base_demo_1_5_tab_content"
                          role="tabpanel"
                        >
						            <Billing history={this.props.history} user = { this.props.user }/>
                        </div>
                        <div
                          className={"tab-pane " + this.state.integrationTab}
                          id="kt_portlet_base_demo_1_1_tab_content"
                          role="tabpanel"
                        >
                          <Integration />
                      </div>
                        <div
                          className={"tab-pane " + this.state.apiInstructionTab}
                          id="kt_portlet_base_demo_1_1_tab_content"
                          role="tabpanel"
                        >
                          <ApiInstruction />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
        </div>{" "}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setBreadCrum,
  setMessage,
  toggleBreadcrum
})(Settings);
