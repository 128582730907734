import React from "react";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import { Segment, Table } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import { DatatableFilter } from "./DatatableFilter";
import DialogBoxDetail from "./DialogBoxDetail";
import { DatatableRow } from "./DatatableRow";
import utilFunc from "../../../../../util_funs";

const tableHeader = [
  {
    title: "Actions",
    width: "1",
    sort: "",
  },
  {
    title: "Name",
    width: "2",
    sort: "name",
  },
  {
    title: "Email",
    width: "3",
    sort: "email",
  }
];
const queryParams = ["_limit", "_order", "_sort", "q", "_page"];
export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "user_id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalErrorMsgLegacy: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      modalstatusNotiAandMisc: false,
      modalstatusNotiAandMiscData: {},
      ISDrenderOption1: [],
      ISDrenderOption2: [],
      notiAndSettingmodalData: {},
      loadNow: false,
      currentChecked: [],
      currentExpanded: [],
      hipaaLoginEmailList: [],
      hipaaLoginPhoneList: [],
    };
  }

  componentDidMount() {
    this.loadData({});

    // this.getAccessModules();
    api.user
      .getProfile()
      .then((resp) => {
        if (resp.data && resp.data.data.profileDetails) {
          const remailCountryCodeArr = resp.data.data.remailCountryCodeArr;
          const defaultCountryCodeArr = resp.data.data.defaultCountryCodeArr;
          this.setState({ ISDrenderOption1: defaultCountryCodeArr });
          this.setState({ ISDrenderOption2: remailCountryCodeArr });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter) => {
    if (filter !== this.state.q) {
      this.loadData({ q: filter, _page: 1 });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalbuttofun = (data) => {
    if (data.modalType == "Delete") {
      this.onDeleteCompletedChatAction();
    }
    // if (data.modalType == 'Popup') {
    //   this.setState({
    //     modalstatus: false,
    //     modaltitle: '',
    //     modalmessage: '',
    //     modalbuttonmsg: {},
    //     modalType: ''
    //   });
    // }
  };

  deleteCompletedChat = (id, table) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Delete",
      modaldata: modaldata,
    });
  };

  setCurrentCheck = (checked) => {
    this.setState({
      currentChecked: checked,
    });
  };
  setCurrentExpand = (checked) => {
    this.setState({
      currentExpanded: checked,
    });
  };

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
    });
  };
  reloadDataTable = () => {
    alert("datatable");
    this.loadData();
  };
  onDeleteCompletedChatAction = () => {
    this.setState({ modalFormLoader: true });
    api.manageUser
      .deleteReportedchatListMember({
        id: this.state.modaldata.id,
        table: this.state.modaldata.table,
      })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          let recordDeleted = this.state.lists.findIndex(
            (list) => list.id === data.data.id
          );
          let oldArr = this.state.lists;
          oldArr.splice(recordDeleted, 1);
          this.setState({
            modalstatus: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modalErrorMsg: false,
          });
         
          this.setState({
            loading: "none",
            message2: "block",
            color2: "green",
            msg2: "Ai phone call notification member has been deleted successfully.",
          });
          this.props.UpdateToasterMsgThree(this.state);
          this.loadData({});
        } catch (err) {
          console.log(err);
        }
      });
  };

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    // let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    // let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    // let time_zone = clientTimeZone;

    // params["time_zone"] = time_zone;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.manageUser.getReportChatLists(query).then((data) => {
        try {
          // let accessModuleStore = data.user_access;
          // data.user_access.forEach((item, index) => {
          //   item.forEach((item2, index2) => {
          //     accessModuleStore.push(item2);
          //   });
          // });
          let datatableRowstemp;
          var isd1 = "+1";
          var phoneno = "";
          var is_recieve_text_message_on_livechat = "0";
          var is_recieve_email_on_livechat = "0";
          var jumpin_message = "";
          var is_recieve_text_message_on_completechat = "0";
          var is_recieve_email_on_completechat = "0";
          var is_leave_note = "0";
          if (data.data.totalrecord) {
            datatableRowstemp = data.data.list.map((list, index) => (
              <DatatableRow
                key={index}
                list={list}
                editUserSubAccounts={this.editUserSubAccounts}
                deleteHipaaUserSubAccounts={this.deleteHipaaUserSubAccounts}
                preOpensetting={this.preOpensetting}
                sessionUser={this.props.sessionUser}
                deleteCompletedChat={this.deleteCompletedChat}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            lists: data.data.list,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  render() {
    return (
      <Segment>
        {this.state.loading && <div className="ui loading form"></div>}
        <DatatableFilter
          filter={this.state.q}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
        />
        <DialogBoxDetail
          obj={this.state}
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalClose={this.modalClose}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
          currentChecked={this.state.currentChecked}
          currentExpanded={this.state.currentExpanded}
          setCurrentCheck={this.setCurrentCheck}
          setCurrentExpand={this.setCurrentExpand}
        />
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          limit={this.state._limit.toString()}
        />
      </Segment>
    );
  }
}
